<template>
  <div>
    <v-form ref="formAdditionalData">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="client_id"
            v-model="client_id"
            label="Client ID"
            prepend-inner-icon="mdi-chevron-double-right"
            :rules="formRules"
            required
            clearable
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="client_secret"
            v-model="client_secret"
            label="Client secret"
            prepend-inner-icon="mdi-chevron-double-right"
            :rules="formRules"
            required
            clearable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="2">
          <v-text-field
            ref="grant_type"
            v-model="grant_type"
            label="Grant Type"
            prepend-inner-icon="mdi-chevron-double-right"
            :rules="formRules"
            :disabled="true"
            required
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            ref="url_token"
            v-model="url_token"
            label="URL token"
            prepend-inner-icon="mdi-chevron-double-right"
            :rules="formRules"
            required
            clearable
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            ref="scope"
            v-model="scope"
            label="Scope"
            prepend-inner-icon="mdi-chevron-double-right"
            :rules="scopeFormRules"
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="Emisor"
            v-model="emisor"
            label="Código Emisor"
            prepend-inner-icon="mdi-chevron-double-right"
            type="number"
            clearable
            :rules="emisorFormRules"
            required
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
          ref="Representation"
            v-model="representation"
            :items="representationOptions"
            item-text="textRepresentation"
            item-value="valueRepresentation"   
            label="Representación gráfica"   
            prepend-inner-icon="mdi-image"
            :rules="formRules"
            required
          />
        </v-col>
      </v-row>

      <v-alert v-show="successAlert" type="success" text>
        {{ msgSuccessAlert }}
      </v-alert>
    </v-card-text>
  </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { representation } from '@/data/listStatus'

export default {
  name: "AdditionalDataClient",
  data() {
    return {
      client_id:"",
      client_secret:"",
      grant_type:"client_credentials",
      scope:"",
      emisor:"",
      representation:"",
      url_token:"https://login.microsoftonline.com/organizations/oauth2/v2.0/token",
      msgSuccessAlert: "Datos actualizados exitosamente",
      successAlert: false,
      validate_forms:false,
      formRules: [(v) => !!v || "Este campo es requerido"],
      representationOptions: representation,
      emisorFormRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => v > 0 || "Este campo no acepta números negativos",
      ],
      scopeFormRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => /^https?:\/\/.+/i.test(v) || "El valor debe ser una url valida",
      ],
    };
  },
  computed: {

  },
  watch: {
    grant_type(value){
      this.$emit('update:grant_type', value);
    },
    client_id(value){
      this.$emit('update:client_id', value);
    },
    client_secret(value){
      this.$emit('update:client_secret', value);
    },
    scope(value){
      this.$emit('update:scope', value);
    },
    url_token(value){
      this.$emit('update:url_token', value);
    },
    emisor(value){
      this.$emit('update:emisor', value);
    },
    representation(value){
      this.$emit('update:representation_graphic', value);
    },
  },
  methods: {
    generateAlertSuccess() {
      this.successAlert = true;
      setTimeout(() => {
        this.successAlert = false;
      }, 3000);
    },
    validateForm(){
      this.validate_forms = this.$refs.formAdditionalData.validate();
      this.$emit('update:validate_forms', this.validate_forms);
    },
  },
  created () {
    this.$emit('update:url_token', this.url_token);
    this.$emit('update:grant_type', this.grant_type);
  },
};
</script>

<style scoped>
</style>
